import { Info, X } from "react-feather";
import ReactMarkdown from "react-markdown";
import { Button } from "@narmi/design_system";

const InfoMessage = ({
  text,
  dismissMessage,
}: {
  text: string;
  dismissMessage: () => void;
}) => (
  <div className="message-content info">
    <Info color="var(--color-warnDark)" />
    <span className="margin--right--l" data-testid="info-message">
      <ReactMarkdown>{text}</ReactMarkdown>
    </span>
    <Button
      kind="plain"
      label={<X className="toast-x" />}
      onClick={dismissMessage}
    />
  </div>
);

export default InfoMessage;
