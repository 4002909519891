import { AlertCircle, X } from "react-feather";
import ReactMarkdown from "react-markdown";
import { Button } from "@narmi/design_system";

const NegativeMessage = ({
  text,
  dismissMessage,
}: {
  text: string;
  dismissMessage: () => void;
}) => (
  <div className="message-content negative">
    <AlertCircle color="var(--color-errorDark)" />
    <span className="margin--right--l" data-testid="notification-negative">
      <ReactMarkdown>{text}</ReactMarkdown>
    </span>
    <Button
      kind="plain"
      label={<X className="toast-x" />}
      onClick={dismissMessage}
    />
  </div>
);

export default NegativeMessage;
